/**
 * The following blocks of code used to reside in index.html
 */
window.$ = jQuery;

window.siteKey = '';

$(document).ready(function () {
  $(document).click(function (event) {
    var clickover = $(event.target);
    var _opened = $('.navbar-collapse').hasClass(
      'navbar-collapse toogleHeader collapse in'
    );
    if (_opened === true && !clickover.hasClass('dummy')) {
      $('button.navbar-toggle').click();
    }
  });
  //Start of code for : IE double click issue on agenda tab
  //serialNo
  jQuery(document.body).on('mousedown', '[name^="serialNo"]', function (event) {
    $(this).closest('[name^="drag"]').attr('draggable', false);
  });
  jQuery(document.body).on('mouseleave', '[name^="serialNo"]', function (
    event
  ) {
    $(this).closest('[name^="drag"]').attr('draggable', true);
  });
  //agendaTitle
  jQuery(document.body).on('mousedown', '[name^="agendaTitle"]', function (
    event
  ) {
    $(this).closest('[name^="drag"]').attr('draggable', false);
  });
  jQuery(document.body).on('mouseleave', '[name^="agendaTitle"]', function (
    event
  ) {
    $(this).closest('[name^="drag"]').attr('draggable', true);
  });
  //agendaExplanation
  jQuery(document.body).on(
    'mousedown',
    '[name^="agendaExplanation"]',
    function (event) {
      $(this).closest('[name^="drag"]').attr('draggable', false);
    }
  );
  jQuery(document.body).on(
    'mouseleave',
    '[name^="agendaExplanation"]',
    function (event) {
      $(this).closest('[name^="drag"]').attr('draggable', true);
    }
  );
  //End of code for : IE double click issue on agenda tab
});
$(window).on('popstate', function () {
  $('.modal').modal('hide');
  $('.modal-backdrop').remove();
});
$(document).on('click', '[data-dismiss=\'modal\']', function () {
  $('.modal-backdrop').remove();
});
